import { Link } from 'react-router-dom';

const Navbar = () => {
    return (
        <nav className="navbar">
            <h1>MyRecify</h1>
            <div className="links">
                <Link to="/">Home</Link><br />
            </div>
        </nav>
    )
}

export default Navbar;