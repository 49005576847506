import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';


function AppWithCallbackAfterRender() {
  return <App tab="home" />
}

const container = document.getElementById('root');
const root = createRoot(container);


root.render(<AppWithCallbackAfterRender />);

reportWebVitals(sendToVercelAnalytics);
