import { useState, useEffect } from "react";

const useFetch = (url) => {
    console.log("useFetch is fetching url " + url);
    const [data, setData] = useState(null);
    const [isPending, setisPending] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        // Used if the page is changed before the fetch returns.
        const abortCont = new AbortController();

        console.log("fetching " + url);
        console.log("useEffect ran.");
        const realData = [
            { title: "recipe 1", id: 1, details: "Lorem ipsum dolor sit amet" },
            { title: "recipe 2", id: 2, details: "Lorem ipsum dolor sit amet" },
            { title: "recipe 3", id: 3, details: "Lorem ipsum dolor sit amet" },
        ];
        setData(realData);
        if (url.includes("id")) {
            console.log("filtering");
            const id = url.split("/")[1];
            if (id >= realData.length) {
                console.log("no recipe found");
                setError("Recipe " + id + " not found.")
            } else {
                setData(realData[id]);
                setError(null);
            }
        }
        setisPending(false);
        /*
        fetch(url, {signal: abortCont.signal })
        .then(res=> {
          if (!res.ok) {
            console.log("could not fetch resource.");
          }
          return res.json()})
        .then(data => {
            console.log(data);
            setRecipes(data);
            setIsPending(false);
        })
        .catch(err => {
            if (err.name === 'AbortError') {
                console.log('fetch aborted.')l
            } else {
                setIsPending(false);
                setError(err.message);
                console.log(err.messsage);
            }
        })
        return () => abortCont.abort();
        */
    }, [url]);

    return { data, isPending, error }
}

export default useFetch;