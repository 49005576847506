import RecipeList from './Recipes';
import logo from './logo.jpeg';
import useFetch from './useFetch';

const Home = () => {
  const { data: recipes, isPending, error} = useFetch("all");
  return (
    <div className="home">
      <p>Welcome to MyRecify!</p>
      <img src={logo} className="App-logo" alt="logo" />
      <p>We'll be serving up some hot recipes soon. For now check out these:</p>
      {recipes && <RecipeList recipes={recipes} />}
    </div>
  )
}

export default Home;