import ReactGA from "react-ga4";
import { inject } from '@vercel/analytics';
import './App.css';
import Navbar from './Navbar';
import Home from './Home';
import Recipe from './Recipe';
import NotFound from "./NotFound";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const TRACKING_ID = "G-Q3M3TXL1D4"; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);

function App() {
  inject();
  return (
      <div className="App">
        <header className="App-header">
          <Router>
          <Navbar />
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="/recipes/:id" element={<Recipe />}></Route>
              <Route path="*" element={<NotFound />}></Route>
            </Routes>
          </Router>
        </header>
      </div>
  );
}

export default App;
