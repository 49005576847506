import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className="not-found">
            <h2>Sorry :( </h2>
            <p>That page was overcooked.</p>
        </div>
    );
}

export default NotFound;