import RecipeList from "./Recipes";
import useFetch from "./useFetch";
import { useParams } from "react-router-dom";

const Recipe = () => {
    const { id } = useParams();
    const { data: recipe, isPending, error} = useFetch("id/" + id);
    console.log("id: " + id);
    console.log(recipe);
    return (
        <div className="recipe">
            <div>Here is recipe {id}</div>
            { isPending && <div>Looading...</div> }
            { error && <div>{ error }</div> }
            { recipe && (<div>{ recipe.details }</div>) }
        </div>
    );
}


export default Recipe;