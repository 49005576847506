import {Link} from "react-router-dom";

const RecipeList = ({recipes}) => {
    return (
        <div className="recipe-list">
            {recipes.map((recipe) => (
                <div className="recipe-preview" key={recipe.id}>
                    <h2><Link to={`/recipes/${recipe.id}`}>{recipe.title}</Link></h2>
                </div>))
            }
        </div>
    );
}

export default RecipeList;